import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export const scrollAnimationHome = () => {
  gsap.from(".slide-in--1", {
    scrollTrigger: {
      trigger: ".slide-in--1",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-100%",
    opacity: 0,
    duration: 1.2,
  })

  gsap.from(".slide-in--2", {
    scrollTrigger: {
      trigger: ".slide-in--2",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-50%",
    opacity: 0,
    duration: 1.2,
  })

  gsap.from(".slide-in--3", {
    scrollTrigger: {
      trigger: ".slide-in--3",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "50%",
    opacity: 0,
    duration: 1.2,
  })

  gsap.from(".slide-in--5", {
    scrollTrigger: {
      trigger: ".slide-in--5",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-100%",
    opacity: 0,
    duration: 1.2,
  })

  gsap.from(".slide-in--6", {
    scrollTrigger: {
      trigger: ".slide-in--6",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "50%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".slide-in--7", {
    scrollTrigger: {
      trigger: ".slide-in--7",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "50%",
    opacity: 0,
    duration: 1.3,
  })

  gsap.from(".slide-in--8", {
    scrollTrigger: {
      trigger: ".slide-in--8",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-100%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".slide-in--9", {
    scrollTrigger: {
      trigger: ".slide-in--9",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "50%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".slide-in--10", {
    scrollTrigger: {
      trigger: ".slide-in--10",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-100%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".slide-in--12", {
    scrollTrigger: {
      trigger: ".slide-in--12",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-50%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".slide-in--13", {
    scrollTrigger: {
      trigger: ".slide-in--13",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "50%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".fade-in--1", {
    scrollTrigger: {
      trigger: ".fade-in--1",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    y: "40%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".fade-in--3", {
    scrollTrigger: {
      trigger: ".fade-in--3",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-40%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".fade-in--4", {
    scrollTrigger: {
      trigger: ".fade-in--4",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    y: "40%",
    opacity: 0,
    duration: 1.2,
  })

  gsap.from(".fade-in--6", {
    scrollTrigger: {
      trigger: ".fade-in--6",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    y: "40%",
    opacity: 0,
    duration: 1.2,
  })
}

export const scrollAnimationAboutUs = () => {
  gsap.from(".slide-in--1", {
    scrollTrigger: {
      trigger: ".slide-in--1",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "-100%",
    opacity: 0,
    duration: 1.2,
  })

  gsap.from(".slide-in--6", {
    scrollTrigger: {
      trigger: ".slide-in--6",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    x: "50%",
    opacity: 0,
    duration: 1,
  })

  gsap.from(".fade-in--2", {
    scrollTrigger: {
      trigger: ".fade-in--2",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    y: "40%",
    opacity: 0,
    duration: 1.3,
  })

  gsap.from(".fade-in--1", {
    scrollTrigger: {
      trigger: ".fade-in--1",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none reverse none",
    },
    y: "40%",
    opacity: 0,
    duration: 1,
  })
}
