import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// How it works
export const howItWorks = <StaticImage
src="../assets/images/how-it-works.png"
layout="constrained"
width={1100}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="How it works process"
/>

// Get started one
export const getStartedOne = <StaticImage
src="../assets/images/get-started-one.png"
layout="constrained"
width={800}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Get Started illustration"
/>

// Get started two
export const getStartedTwo = <StaticImage
src="../assets/images/get-started-two.png"
layout="constrained"
width={800}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Get Started illustration"
/>

// Get started three
export const getStartedThree = <StaticImage
src="../assets/images/get-started-three.png"
layout="constrained"
width={800}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Get Started illustration"
/>

// Why Clear Factor - FAIR
export const whyFair = <StaticImage
src="../assets/images/why-fair.png"
layout="constrained"
width={1000}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Why Clear Factor illustration"
/>

// Why Clear Factor - SIMPLE
export const whySimple = <StaticImage
src="../assets/images/why-simple.png"
layout="constrained"
width={800}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Why Clear Factor illustration"
/>

// Why Clear Factor - OPEN
export const whyOpen = <StaticImage
src="../assets/images/why-open.png"
layout="constrained"
width={800}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Why Clear Factor illustration"
/>

// About Us origins - one
export const aboutUsOriginsOne = <StaticImage
src="../assets/images/about-us-origins-one.png"
layout="constrained"
width={1000}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Origins illustration"
/>

// About Us origins - two
export const aboutUsOriginsTwo = <StaticImage
src="../assets/images/about-us-origins-two.png"
layout="constrained"
width={1000}
formats={["AUTO", "WEBP", "AVIF"]}
placeholder="none"
alt="Origins illustration"
/>
